.custom-modal {
  width: 800px !important;
  height: 80%;
}

.sighting-details-container {
  display: flex;
  width: 100%;
  height: 100%;
}

.sighting-details-info {
  flex: 1;
  padding: 16px;
}

.sighting-details-images {
  flex: 1;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sighting-details-carousel {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(235, 235, 235);
  width: 400px !important;
  height: 400px !important;
}

.sighting-details-image {
  max-width: 400px !important;
  max-height: 400px !important;
}

.slick-slide {
  text-align: center;
}

.image-container {
  width: auto!important;
}